@import "variables";

body, html {
  height:100%
}

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}

// Titles
h1, h2, h3, h4, h5, h6 {
  font-family: $titleFont;
  color:$titleColor;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top:0
}

.h1, h1 {
  font-size: 35px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing:-.03em
}

@media only screen and (min-width: 768px) {
  .h1, h1 {
    font-size:45px
  }
}

@media only screen and (min-width: 1220px) {
  .h1, h1 {
    font-size:60px
  }
}

.h2, h2 {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing:-.02em
}

@media only screen and (min-width: 768px) {
  .h2, h2 {
    font-size:30px
  }
}

@media only screen and (min-width: 1220px) {
  .h2, h2 {
    font-size:35px
  }
}

.h3, h3 {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing:-.02em
}

@media only screen and (min-width: 768px) {
  .h3, h3 {
    font-size:25px
  }
}

@media only screen and (min-width: 1220px) {
  .h3, h3 {
    font-size:30px
  }
}

.h4, h4 {
  margin-top: 25px;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing:0
}

@media only screen and (min-width: 768px) {
  .h4, h4 {
    font-size:22px
  }
}

@media only screen and (min-width: 1220px) {
  .h4, h4 {
    font-size:22px
  }
}

.h5, h5 {
  margin-top: 15px;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing:0
}

@media only screen and (min-width: 768px) {
  .h5, h5 {
    font-size:20px
  }
}

@media only screen and (min-width: 1220px) {
  .h5, h5 {
    font-size:20px
  }
}

.h6, h6 {
  margin-top: 15px;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing:0
}

@media only screen and (min-width: 768px) {
  .h6, h6 {
    font-size:20px
  }
}

@media only screen and (min-width: 1220px) {
  .h6, h6 {
    font-size:20px
  }
}

// Basic Elements

ol, p, ul {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing:0
}

@media only screen and (min-width: 768px) {
  ol, p, ul {
    font-size:20px
  }
}

@media only screen and (min-width: 1220px) {
  ol, p, ul {
    font-size:22px
  }
}

ol strong, p strong, ul strong {
  font-weight:600
}

ol:first-child, p:first-child, ul:first-child {
  margin-top:0
}

ol, ul {
  list-style-position:inside
}

blockquote {
  margin-top: 15px;
  border-left: 2px solid #2a2f36;
  padding: 10px 0 10px 20px;
  font-size: 25px;
  font-weight: 300;
  letter-spacing: -.02em;
  line-height:1.6
}

@media only screen and (min-width: 768px) {
  blockquote {
    border-left-width: 3px;
    padding: 15px 0 15px 30px;
    font-size:35px
  }
}

@media only screen and (min-width: 1220px) {
  blockquote {
    border-left-width: 4px;
    padding: 20px 0 20px 40px;
    font-size:45px
  }
}

blockquote p {
  font-size: 25px;
  font-weight: 300;
  letter-spacing: -.02em;
  line-height:1.6
}

@media only screen and (min-width: 768px) {
  blockquote p {
    font-size:35px
  }
}

@media only screen and (min-width: 1220px) {
  blockquote p {
    font-size:45px
  }
}

// Other Elements
hr {
  margin: 40px 0;
  border: 0;
  height: 2px;
  background:#f4f5f6
}

::selection {
  background: $fillColorTertiary;
  color:#fff
}

::-moz-selection {
  background: $fillColorTertiary;
  color:#fff
}
