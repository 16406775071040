// Fixed Colors
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorAshGrey: #b5c2b7;
$colorCoolGrey: #8c93a8;
$colorEnglishViolet: #62466b;
$colorEnglishVioletDark: #45364b;
$colorRaisinBlack: #2d2327;
$colorVibrantBlue: #3498db;

$backgroundColor: $colorWhite;
$bodyColor: $colorBlack;
$bodyColorSecondary: lighten($bodyColor, 20%);
$bodyColorTertiary: lighten($bodyColorSecondary, 20%);
$bodyColorQuaternary: lighten($bodyColorTertiary, 20%);

$fillColor: $colorEnglishViolet;
$fillColorSecondary: lighten($colorEnglishViolet, 20%);
$fillColorTertiary: lighten($colorEnglishViolet, 40%);
$fillColorQuaternary: lighten($colorEnglishViolet, 60%);

$titleColor: $fillColorSecondary;

$linkColor: $colorRaisinBlack;
$linkColorHover: lighten($linkColor, 40%);

$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$titleFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";