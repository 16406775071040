@import "variables";

// Links
a {
  text-decoration: none;
  color: $linkColor;
  font-weight: 600;
  -webkit-transition: all .15s ease-in-out;
  -moz-transition: all .15s ease-in-out;
  -ms-transition: all .15s ease-in-out;
  -o-transition: all .15s ease-in-out;
  transition:all .15s ease-in-out
}

a:hover {
  color: $linkColorHover;
}

.button {
  display: inline-block;
  padding: 9px 10px 10px;
  font-size: 13px;
  line-height: 1;
  border: 2px solid $fillColorSecondary;
  background: $fillColor;
  color: $backgroundColor;
  font-weight: 600;
  letter-spacing: 0;
  cursor:pointer
}

@media only screen and (min-width: 768px) {
  .button {
    padding: 11px 20px 12px;
    font-size:15px
  }
}

.button:hover {
  background: $fillColorSecondary;
  border-color: $fillColorTertiary;
  color: $backgroundColor
}

.button svg {
  margin-right:5px
}

.button svg:last-child {
  margin-right: 0;
  margin-left:5px
}

@media only screen and (min-width: 768px) {
  .button svg {
    margin-right:8px
  }

  .button svg:last-child {
    margin-right: 0;
    margin-left:8px
  }
}

.button--large {
  padding: 15px 20px 16px;
  font-size:13px
}

@media only screen and (min-width: 768px) {
  .button--large {
    padding: 19px 40px 20px;
    font-size:17px
  }
}

.button--light {
  background: 0 0;
  border-color: #2a2f36;
  color:#2a2f36
}

.button--light:hover {
  background: #3498db;
  border-color: #3498db;
  color:#fff
}