@import "variables";

// Pages
.page {
  float: left;
  clear: both;
  width:100%;

  .content {
    float: left;
    clear: both;
    width:100%;

  }
}

section {
  margin-top:40px
}

@media only screen and (min-width: 768px) {
  section {
    margin-top:50px
  }
}

@media only screen and (min-width: 1024px) {
  section {
    margin-top:60px
  }
}

@media only screen and (min-width: 1220px) {
  section {
    margin-top:80px
  }
}

// Wrap
.wrap {
  position: relative;
  margin: 0 auto;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .wrap {
    padding: 0;
    max-width:680px
  }
}

@media only screen and (min-width: 1024px) {
  .wrap {
    max-width:900px
  }
}

@media only screen and (min-width: 1220px) {
  .wrap {
    max-width:1100px
  }
}

// Header
.header {
  margin-top:20px;

  .title {
    display: inline-block;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -.02em;
    line-height: 1;
    color:#2a2f36;

    &:hover {
      color: $titleColor;
    }
  }

  .logo {
    display: block;
    width: 200px;
    max-width:80%;

    img {
      width: 100%;
      height:auto;
    }
  }
}

@media only screen and (min-width: 768px) {
  .header {
    margin-top:25px;

    .logo {
      max-width:50%;
    }

    .title {
      font-size:30px;
    }
  }
}

@media only screen and (min-width: 1220px) {
  .header {
    margin-top:30px;

    .title {
      font-size:35px;
    }
  }
}

// Footer
.footer {
  -webkit-transition: all .4s ease-in-out .8s;
  -moz-transition: all .4s ease-in-out .8s;
  -ms-transition: all .4s ease-in-out .8s;
  -o-transition: all .4s ease-in-out .8s;
  transition: all .4s ease-in-out .8s;
  padding:40px 0
}

@media only screen and (min-width: 768px) {
  .footer {
    padding:50px 0
  }
}

@media only screen and (min-width: 1024px) {
  .footer {
    padding:60px 0
  }
}

@media only screen and (min-width: 1220px) {
  .footer {
    padding:80px 0
  }
}

.footer__logo {
  display: block;
  width:px
}

.footer__title {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -.02em;
  line-height: 1;
  color:#6c7a89
}

.footer__title:hover {
  color:#2a2f36
}

@media only screen and (min-width: 768px) {
  .footer__title {
    font-size:30px
  }
}

@media only screen and (min-width: 1220px) {
  .footer__title {
    font-size:35px
  }
}

.footer__text {
  color:#abb7b7
}

.footer__copyright {
  display: block;
  margin-top: 10px;
  font-size: 13px;
  color:#abb7b7
}

.footer__copyright a {
  margin-left: 10px;
  color: #abb7b7;
  font-weight:400
}

.footer__copyright a:hover {
  color:#6c7a89
}

.footer__copyright span {
  margin-top: 10px;
  display:inline-block
}

@media only screen and (min-width: 768px) {
  .footer__copyright span {
    margin-top:0
  }
}

.footer .socials {
  margin-top:25px
}

@media only screen and (min-width: 1220px) {
  .footer .socials {
    position: absolute;
    top: 0;
    right: 0;
    margin-top:0
  }
}

// Hero
.hero {
  position: relative;
  margin-top: 20px;
  padding: 60px 0 100px;
  background: $colorEnglishViolet;

  h1 {
    color: #fff;
  }

  p {
    color:rgba(255, 255, 255, .8);
  }

  .socials {
    margin-top:15px;

    a {
      background: rgba(255, 255, 255, .8);
      color:#132930;

      &:hover {
        background:#fff;
        color: $fillColorSecondary;
      }
    }
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    background-blend-mode: luminosity;
    background-color: $fillColor;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($fillColor, 0.6);
  }
}

@media only screen and (min-width: 768px) {
  .hero {
    margin-top: 25px;
    padding:120px 0 180px;

    h1 {
      white-space:pre-line;
    }

    p {
      margin-top:25px;
    }

    .socials {
      margin-top:25px;
    }
  }
}

@media only screen and (min-width: 1220px) {
  .hero {
    margin-top: 30px;
    padding:160px 0 220px;

    p {
      margin-top:30px;
    }

    .socials {
      margin-top:30px;
    }
  }
}