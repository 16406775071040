@import "variables";

.header {
  .title {
    margin-left: 10px;

    &:hover {
      color: $fillColor;
    }
  }
}

// Navigation
.nav {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $colorCoolGrey;
  overflow: hidden;

  max-height: 0;
  transition: max-height .5s ease-out;
  margin-top: 10px;
  z-index: 100;

  ul {
    list-style: none;
  }

  li {
    a {
      display: block;

      font-size: 30px;
      font-weight: 600;
      color: #2a2f36;
      padding: 0 30px;

      &:hover {
        color: lighten($fillColor, 20%);
      }
    }
  }
}

.hamburger {
  cursor: pointer;
  float: right;
  padding: 10px 20px;
}

.hamburger-line {
  background: $bodyColorSecondary;
  display: block;
  height: 2px;
  position: relative;
  width: 24px;

  &:before, &:after {
    background: $bodyColorSecondary;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }

  &:before {
    top: 5px;
  }

  &:after {
    top: -5px;
  }
}

.side-menu {
  display: none;
}

.side-menu:checked ~ nav {
  max-height: 100%;
}

.side-menu:checked ~ .hamburger .hamburger-line {
  background: transparent;

  &:before {
    transform: rotate(-45deg);
    top:0;
  }

  &:after {
    transform: rotate(45deg);
    top:0;
  }
}

@media only screen and (min-width: 768px) {
  .header {
    .title {
      margin-left: 0px;
    }
  }

  .nav {
    max-height: none;
    top: 0;
    position: relative;
    float: right;
    width: fit-content;
    background-color: transparent;

    li {
      float: left;

      a {
        font-size: 20px;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .hamburger {
    display: none;
  }
}

@media only screen and (min-width: 1220px) {
  .menu {
    li {
      a {
        font-size:17px;
      }
    }
  }
}