@import "variables";

// Single Content
.hero.single p {
  margin-top:10px;
}

.single {
  margin-top: 0;
  padding-bottom: 40px;
  background:#f4f5f6
}

@media only screen and (min-width: 768px) {
  .single {
    padding-bottom:60px
  }
}

@media only screen and (min-width: 1220px) {
  .single {
    padding-bottom:80px
  }
}

.single-post {
  position: relative;
  margin-top: -40px;
  background: #fff;
  padding: 16px 20px;
  -webkit-box-shadow: 0 6px 16px rgba(0, 0, 0, .05);
  -moz-box-shadow: 0 6px 16px rgba(0, 0, 0, .05);
  box-shadow:0 6px 16px rgba(0, 0, 0, .05);

  hr {
    margin:30px auto;
  }

  blockquote, figure {
    margin:20px auto;
  }
}

@media only screen and (min-width: 768px) {
  .single-post {
    margin-top: -60px;
    padding: 32px 40px;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .05);
    box-shadow:0 10px 20px rgba(0, 0, 0, .05);

    hr {
      margin-top: 40px;
      margin-bottom:40px;
    }

    blockquote, figure {
      margin:30px auto;
    }

    blockquote p {
      max-width: 100%;
      padding:0;
    }

    p a {
      font-weight: 600;
      border-bottom:2px solid $fillColorTertiary;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .single-post {
    padding:50px 60px;

    blockquote, figure {
      margin:40px auto;
    }
  }
}

@media only screen and (min-width: 1220px) {
  .single-post {
    padding: 70px 80px;
    -webkit-box-shadow: 0 14px 26px rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 14px 26px rgba(0, 0, 0, .05);
    box-shadow:0 14px 26px rgba(0, 0, 0, .05)
  }
}


.single-post p .button {
  margin-top:10px
}

.single-post ol ol, .single-post ol ul, .single-post ul ol, .single-post ul ul {
  max-width: 100%;
  padding-left:25px
}

.single-post .highlighter-rouge, .single-post table {
  margin: 20px auto;
  width:100%
}

@media only screen and (min-width: 768px) {
  .single-post .highlighter-rouge, .single-post table {
    margin:30px auto
  }
}

@media only screen and (min-width: 1220px) {
  .single-post .highlighter-rouge, .single-post table {
    margin:40px auto
  }
}

.single-post .highlight {
  display: block;
  padding: 0;
  line-height: 1.5;
  font-size: 15.3px;
  line-height: 1.6;
  overflow:auto
}

@media only screen and (min-width: 768px) {
  .single-post .highlight {
    font-size:17px
  }
}

@media only screen and (min-width: 1220px) {
  .single-post .highlight {
    font-size:18.7px
  }
}

.single-post .highlight .highlight {
  padding:20px
}

@media only screen and (min-width: 768px) {
  .single-post .highlight .highlight {
    padding:30px
  }
}

@media only screen and (min-width: 1220px) {
  .single-post .highlight .highlight {
    padding:40px
  }
}

.single-post .table-wrap {
  display: block;
  width: 100%;
  overflow-x:auto
}

.single-post table {
  min-width: 600px;
  border:2px solid #2a2f36
}

.single-post table thead {
  background: #2a2f36;
  color: #fff;
  text-align: left;
  font-weight:600
}

.single-post table thead th {
  font-weight:600
}

.single-post table td, .single-post table th {
  padding: 10px;
  font-size: 18px;
  font-weight:400
}

@media only screen and (min-width: 768px) {
  .single-post table td, .single-post table th {
    padding:15px
  }
}

@media only screen and (min-width: 1220px) {
  .single-post table td, .single-post table th {
    padding: 20px;
    font-size:20px
  }
}

.single-post table tr:nth-child(2n) {
  background:#f4f5f6
}

.single-post sup a.footnote {
  display: inline-block;
  margin-left: 5px;
  min-width: 16px;
  height: 16px;
  border-radius: 100%;
  border-bottom: none;
  padding: 1px 3px;
  background: #f4f5f6;
  font-size: 10px;
  text-align: center;
  color:#abb7b7
}

.single-post sup a.footnote:hover {
  background: #abb7b7;
  color:#fff
}

.single-post .footnotes {
  margin-top:40px
}

@media only screen and (min-width: 768px) {
  .single-post .footnotes {
    margin-top:60px
  }
}

@media only screen and (min-width: 1220px) {
  .single-post .footnotes {
    margin-top:80px
  }
}

.single-post .footnotes ol {
  list-style: none;
  counter-reset:footnotes
}

.single-post .footnotes ol li {
  margin-top: 5px;
  font-size: 13px;
  counter-increment:footnotes
}

.single-post .footnotes ol li:before {
  content: counter(footnotes);
  box-sizing: border-box;
  display: inline-block;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border-bottom: none;
  padding: 2px 3px;
  background: #f4f5f6;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color:#abb7b7
}

.single-post .footnotes ol li p {
  display: inline;
  max-width: 100%;
  font-size:13px
}

.single-post .footnotes ol li p a.reversefootnote {
  border-bottom: 0;
  vertical-align:sub
}

// Image & Video Wrap

.image-wrap, .video-wrap {
  position: relative;
  margin:20px auto;

  &:first-child {
    margin-top:0;
  }
}

@media only screen and (min-width: 768px) {
  .image-wrap, .video-wrap {
    margin:30px auto
  }
}

@media only screen and (min-width: 1220px) {
  .image-wrap, .video-wrap {
    margin:40px auto
  }
}

.image-wrap img {
  margin:0 auto
}

.video {
  position: relative;
  width: 100%;
  height:0
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100%
}

// Socials

.socials {
  list-style: none;
  margin: 0;
  padding:0;

  li {
    display: inline-block;
    margin-left:2px;

    &:first-child {
      margin-left:0;
    }

    a {
      position: relative;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #abb7b7;
      font-size: 20px;
      line-height: 1;
      color: #fff;

      &:hover {
        background: $fillColor;
        color:#fff;
      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -52%);
        -o-transform: translate(-50%, -52%);
        -ms-transform: translate(-50%, -52%);
        -webkit-transform: translate(-50%, -52%);
        transform:translate(-50%, -52%);
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .socials {
    li {
      a {
        width: 50px;
        height: 50px;
        font-size:25px;
      }
    }
  }
}

// Lisiting

.listing {
  margin-top:-40px
}

@media only screen and (min-width: 768px) {
  .listing {
    margin-top:-60px
  }
}

// Posts

.post {
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  margin-top: 30px;
  cursor:pointer;

  &:first-child {
    margin-top:0;
  }

  &:hover .image {
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform:scale(1.2);
  }

  &:hover .content {
    -webkit-box-shadow: 0 6px 16px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 6px 16px rgba(0, 0, 0, .1);
    box-shadow:0 6px 16px rgba(0, 0, 0, .1);
  }

  &:hover .title a {
    color: $fillColorSecondary;
  }

  .image-wrap {
    position: relative;
    height: 0;
    margin: 0;
    padding-bottom: 56.25%;
    overflow:hidden;
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-transition: all 1s cubic-bezier(.2, .3, 0, 1);
    -moz-transition: all 1s cubic-bezier(.2, .3, 0, 1);
    -ms-transition: all 1s cubic-bezier(.2, .3, 0, 1);
    -o-transition: all 1s cubic-bezier(.2, .3, 0, 1);
    transition:all 1s cubic-bezier(.2, .3, 0, 1);
  }

  .content-wrap {
    float: left;
    width: 100%;
    position: relative;
    padding: 0 20px 20px;
    background:#f4f5f6;
  }

  .content {
    position: relative;
    margin-top: -20px;
    padding: 20px;
    background: #fff;
    -webkit-box-shadow: 0 6px 16px rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 6px 16px rgba(0, 0, 0, .05);
    box-shadow: 0 6px 16px rgba(0, 0, 0, .05);
    -webkit-transition: all .4s cubic-bezier(.2, .3, 0, 1);
    -moz-transition: all .4s cubic-bezier(.2, .3, 0, 1);
    -ms-transition: all .4s cubic-bezier(.2, .3, 0, 1);
    -o-transition: all .4s cubic-bezier(.2, .3, 0, 1);
    transition:all .4s cubic-bezier(.2, .3, 0, 1);
  }

  .title a {
    font-weight:700;
    color: $colorCoolGrey;
  }

  .subtitle {
    margin-top: 5px;
    font-size: 15px;
    color: $fillColorSecondary;
  }
}

@media only screen and (min-width: 768px) {
  .post {
    .content-wrap {
      padding:0 30px 30px;
    }

    .content {
      margin-top: -30px;
      padding:30px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .post {
    margin-top: 60px;
    background:#f4f5f6;

    &:nth-child(2n) {
      .image-wrap {
        left: auto;
        right:0;
      }

      .content-wrap {
        float: left;
        padding:30px 0 30px 30px;
      }

      .content {
        margin:0 -30px 0 0;
      }
    }

    .image-wrap {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50%;
      height: auto;
      padding-bottom:0;
    }

    .content-wrap {
      float: right;
      padding: 30px 30px 30px 0;
      width:50%;
    }

    .content {
      margin: 0 0 0 -30px;
      min-height:400px;
    }
  }
}

// Pagination

.pagination {
  margin-top:30px
}

@media only screen and (min-width: 1024px) {
  .pagination {
    margin-top:60px
  }
}

.pagination__next, .pagination__prev {
  width: 50%;
  padding: 20px;
  border:0
}

@media only screen and (min-width: 768px) {
  .pagination__next, .pagination__prev {
    padding: 30px;
    font-size:18px
  }
}

@media only screen and (min-width: 1220px) {
  .pagination__next, .pagination__prev {
    font-size:20px
  }
}

.pagination__next:hover, .pagination__prev:hover {
  background:#3498db
}

.pagination__prev {
  float: left;
  background:#6c7a89
}

.pagination__next {
  float: right;
  text-align: right;
  background:#2a2f36
}

// Contact Form
.contact-form {
  margin: 20px auto;
  width:100%
}

@media only screen and (min-width: 768px) {
  .contact-form {
    margin:30px auto
  }
}

@media only screen and (min-width: 1220px) {
  .contact-form {
    margin:40px auto
  }
}

.contact-form:first-child {
  margin-top:0
}

.contact-form__item {
  margin-top:15px
}

@media only screen and (min-width: 768px) {
  .contact-form__item {
    margin-top:20px
  }
}

@media only screen and (min-width: 1220px) {
  .contact-form__item {
    margin-top:30px
  }
}

.contact-form__item:first-child {
  margin-top:0
}

.contact-form__item--error .contact-form__input, .contact-form__item--error .contact-form__textarea {
  border-color:#d64541
}

.contact-form__label {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color:#6c7a89
}

@media only screen and (min-width: 768px) {
  .contact-form__label {
    font-size:20px
  }
}

@media only screen and (min-width: 1220px) {
  .contact-form__label {
    font-size:22px
  }
}

.contact-form__input, .contact-form__textarea {
  width: 100%;
  margin-top: 8px;
  border: 2px solid #ddd;
  border-radius: 2px;
  padding: 10px 12px;
  background: #fff;
  font-size: 18px;
  line-height: 1.4;
  color: #2a2f36;
  -webkit-transition: all .15s ease-in-out;
  -moz-transition: all .15s ease-in-out;
  -ms-transition: all .15s ease-in-out;
  -o-transition: all .15s ease-in-out;
  transition:all .15s ease-in-out
}

@media only screen and (min-width: 768px) {
  .contact-form__input, .contact-form__textarea {
    padding: 15px 18px;
    font-size:20px
  }
}

@media only screen and (min-width: 1220px) {
  .contact-form__input, .contact-form__textarea {
    font-size:22px
  }
}

.contact-form__input:focus, .contact-form__textarea:focus {
  outline: 0;
  border-color:#3498db
}

::-webkit-input-placeholder {
  color:#d4dada
}

::-moz-placeholder {
  color:#d4dada
}

:-ms-input-placeholder {
  color:#d4dada
}

:-moz-placeholder {
  color:#d4dada
}

.contact-form__textarea {
  min-height: 200px;
  resize:none
}